import { find } from 'lodash';

import {
  CLEAR_REPORT_LOST_STOLEN,
  CLEAR_RELOAD_AMOUNT_SELECTED,
  CLOSE_PAY_OVERLAY,
  SET_RELOAD_AMOUNT_SELECTED,
  FETCH_SVC_CARDS_ERROR,
  FETCH_SVC_CARDS_SUCCESS,
  FETCH_SVC_CARDS,
  SET_PRIMARY_SVC_CARD,
  SET_PRIMARY_SVC_CARD_SUCCESS,
  SET_PRIMARY_SVC_CARD_ERROR,
  ADD_SVC_CARD,
  ADD_SVC_CARD_SUCCESS,
  ADD_SVC_CARD_ERROR,
  REMOVE_SVC_CARD,
  REMOVE_SVC_CARD_SUCCESS,
  REMOVE_SVC_CARD_ERROR,
  REPORT_LOST_SVC,
  REPORT_LOST_SVC_SUCCESS,
  REPORT_LOST_SVC_INCOMPLETE,
  REPORT_LOST_SVC_ERROR,
  FETCH_SVC_CARD_BALANCE,
  FETCH_SVC_CARD_BALANCE_SUCCESS,
  FETCH_SVC_CARD_BALANCE_ERROR,
  RELOAD_SVC_CARD_BALANCE,
  RELOAD_SVC_CARD_BALANCE_SUCCESS,
  RELOAD_SVC_CARD_BALANCE_ERROR,
  SET_CARD_IN_MANAGEMENT,
  UPDATE_NICKNAME,
  UPDATE_NICKNAME_SUCCESS,
  UPDATE_NICKNAME_ERROR,
} from '../actions';
import {
  STORED_VALUE_CARD_REALTIME_BALANCE,
  GET_STORED_VALUE_CARD_LIST,
  SET_PRIMARY_STORED_VALUE_CARD,
  ADD_PHYSICAL_STORED_VALUE_CARD,
  ADD_DIGITAL_STORED_VALUE_CARD,
  REMOVE_STORED_VALUE_CARD,
  REPORT_AND_REPLACE_LOST_STOLEN_SVC,
  RELOAD_STORED_VALUE_CARD,
  UPDATE_SVC_NICKNAME,
} from 'shared/universal/gql-operation-ids';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

import { accertifyDataCollectorIsEnabledSelector } from 'shared/app/state/selectors/accertify-data-collector';
import transformImageUrls from './utils/transform-image-urls';
import { svcCardsDataSelector } from './selectors';

export const fetchSvcCards =
  (coords) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_SVC_CARDS, payload: coords });
    return gqlFetch({
      operationId: GET_STORED_VALUE_CARD_LIST,
      destinationType: API_PROXY_V1,
    })
      .then((payload) => {
        if (payload.errors) {
          throw new Error(payload.errors[0].message);
        }

        dispatch({
          type: FETCH_SVC_CARDS_SUCCESS,
          payload: payload.user.storedValueCardList.map(transformImageUrls),
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_SVC_CARDS_ERROR, error });
      });
  };

export const setCardInManagement = (cardId) => ({
  type: SET_CARD_IN_MANAGEMENT,
  payload: cardId,
});

export const setPayOverlayClosed = () => (dispatch) => {
  dispatch({ type: CLOSE_PAY_OVERLAY });
};

export const setPrimarySvcCard =
  (cardId) =>
  (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const nextPrimaryCard = find(svcCardsDataSelector(state), { cardId });
    const previousPrimaryCard = find(svcCardsDataSelector(state), {
      isPrimary: true,
    });

    dispatch({ type: SET_PRIMARY_SVC_CARD, payload: nextPrimaryCard });

    return gqlFetch({
      operationId: SET_PRIMARY_STORED_VALUE_CARD,
      destinationType: API_PROXY_V1,
      variables: { cardId },
    })
      .then((data) => {
        const payload = data?.setPrimaryStoredValueCard;

        if (!payload) {
          throw new Error('Failed to set primary svc');
        } else {
          dispatch({
            type: SET_PRIMARY_SVC_CARD_SUCCESS,
            payload: nextPrimaryCard,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_PRIMARY_SVC_CARD_ERROR,
          error,
          payload: previousPrimaryCard,
        });
      });
  };

export const addPhysicalSvcCard =
  ({ cardNumber, pin, makePrimaryCard }) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: ADD_SVC_CARD });

    return gqlFetch({
      operationId: ADD_PHYSICAL_STORED_VALUE_CARD,
      destinationType: API_PROXY_V1,
      variables: { cardNumber, pin, makePrimaryCard },
      includeRisk: true,
    })
      .then((data) => {
        const payload = data?.addPhysicalStoredValueCard;

        if (!payload) {
          throw new Error('Failed to add physical card');
        } else {
          dispatch({
            type: ADD_SVC_CARD_SUCCESS,
            payload: transformImageUrls(payload),
          });
          if (makePrimaryCard) {
            if (payload.isPrimary) {
              dispatch({ type: SET_PRIMARY_SVC_CARD, payload });
            } else {
              const err = new Error('Failed to make card as primary');
              err.code = 'makePrimaryCardError';
              err.cardId = payload.cardId;
              throw err;
            }
          }
          return payload;
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_SVC_CARD_ERROR, error });
        throw error;
      });
  };

export const addDigitalSvcCard =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: ADD_SVC_CARD });
    return gqlFetch({
      operationId: ADD_DIGITAL_STORED_VALUE_CARD,
      destinationType: API_PROXY_V1,
      includeRisk: true,
    })
      .then((data) => {
        const payload = data?.addDigitalStoredValueCard;

        if (!payload) {
          throw new Error('Failed to add digital card');
        } else {
          dispatch({
            type: ADD_SVC_CARD_SUCCESS,
            payload: transformImageUrls(payload),
          });
          return payload;
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_SVC_CARD_ERROR, error });
        throw error;
      });
  };

export const removeSvcCard =
  (cardId) =>
  (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const cardToRemove = find(svcCardsDataSelector(state), { cardId });

    dispatch({
      type: REMOVE_SVC_CARD,
      payload: cardToRemove,
    });

    return gqlFetch({
      operationId: REMOVE_STORED_VALUE_CARD,
      destinationType: API_PROXY_V1,
      variables: { cardId },
      includeRisk: true,
    })
      .then(() => {
        dispatch({
          type: REMOVE_SVC_CARD_SUCCESS,
          payload: cardToRemove,
        });
        return cardToRemove;
      })
      .catch((error) => {
        dispatch({
          error,
          type: REMOVE_SVC_CARD_ERROR,
          payload: cardToRemove,
        });
        throw error;
      });
  };

export const getSvcCardBalance =
  (cardId) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_SVC_CARD_BALANCE, payload: cardId });

    return gqlFetch({
      operationId: STORED_VALUE_CARD_REALTIME_BALANCE,
      destinationType: API_PROXY_V1,
      variables: {
        cardId,
      },
    })
      .then((cardData) => {
        const payload = {
          cardId,
          balance: cardData.storedValueCardRealtimeBalance,
          lastBalanceCheckTime: Date.now(),
        };

        return dispatch({ type: FETCH_SVC_CARD_BALANCE_SUCCESS, payload });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_SVC_CARD_BALANCE_ERROR,
          error,
          payload: cardId,
        })
      );
  };

export const reloadSvcCardBalance =
  (formData) =>
  (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const { amount, payment, paymentInstrument, reloadSource, svcCard } =
      formData;
    dispatch({ type: RELOAD_SVC_CARD_BALANCE });

    return gqlFetch({
      operationId: RELOAD_STORED_VALUE_CARD,
      destinationType: API_PROXY_V1,
      variables: {
        paymentMethod: paymentInstrument || reloadSource,
        svcCard: svcCard || payment,
        amount: parseFloat(amount),
      },
      includeRisk: true,
      includeAccertify: accertifyDataCollectorIsEnabledSelector(state),
    })
      .then((data) => {
        const reloadPayload = data.reloadStoredValueCard;
        if (!reloadPayload) {
          throw new Error('Failed to reload svc');
        }
        dispatch({
          type: RELOAD_SVC_CARD_BALANCE_SUCCESS,
          payload: reloadPayload,
        });
      })
      .catch((error) => {
        dispatch({ type: RELOAD_SVC_CARD_BALANCE_ERROR, error });
        throw error;
      });
  };

export const editNickname =
  (cardId, nickname) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: UPDATE_NICKNAME, payload: { cardId, nickname } });
    return gqlFetch({
      operationId: UPDATE_SVC_NICKNAME,
      destinationType: API_PROXY_V1,
      variables: { cardId, nickname },
    })
      .then((data) => {
        const payload = data?.updateSVCNickname;
        if (!payload) {
          throw new Error('Failed to edit nickname');
        } else {
          dispatch({
            type: UPDATE_NICKNAME_SUCCESS,
            payload: data.updateSVCNickname,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: UPDATE_NICKNAME_ERROR, payload: error });
      });
  };

export const clearReloadAmountSelected = () => (dispatch) => {
  dispatch({ type: CLEAR_RELOAD_AMOUNT_SELECTED });
};

export const setReloadAmountSelected =
  ({ amount, triggerAmount }) =>
  (dispatch) => {
    dispatch({
      type: SET_RELOAD_AMOUNT_SELECTED,
      payload: { amount, triggerAmount },
    });
  };

export const reportLostStolenSvc =
  (cardId) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: CLEAR_REPORT_LOST_STOLEN });

    dispatch({ type: REPORT_LOST_SVC, payload: { lostStolenSvcId: cardId } });

    return gqlFetch({
      destinationType: API_PROXY_V1,
      operationId: REPORT_AND_REPLACE_LOST_STOLEN_SVC,
      variables: { cardId },
      includeRisk: true,
    })
      .then((response) => {
        const replacementSvc = response?.reportAndReplaceLostStolenSvc ?? null;
        const replacementSvcCardId = replacementSvc?.cardId ?? null;
        const lostStolenComplete = replacementSvcCardId !== null;
        const payload = {
          lostStolenComplete,
          lostStolenReplacementSvc: replacementSvc,
        };
        if (!lostStolenComplete) {
          // long running process, encounters processing timeout,
          // completes later, new card available momentarily
          dispatch({ type: REPORT_LOST_SVC_INCOMPLETE, payload });
        } else {
          dispatch({ type: REPORT_LOST_SVC_SUCCESS, payload });
          return replacementSvc;
        }
      })
      .catch((error) => {
        // error message shape from gql includes { type: 'report' }
        const isReportTypeError = Boolean(error?.message?.type);
        if (isReportTypeError) {
          // dispatch the error to stop loading state
          dispatch({ type: REPORT_LOST_SVC_ERROR });
          // re-throw to handle error in the client
          throw Error(error);
        } else {
          // long running process, encounters processing error,
          // completes later, new card available momentarily
          dispatch({
            type: REPORT_LOST_SVC_INCOMPLETE,
            payload: { lostStolenComplete: false },
          });
        }
      });
  };
