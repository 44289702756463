import { createSelector } from 'reselect';

import { currentLocaleSelector } from './locales';
import { storeConfirmationTimeoutSelector, configSelector } from './config';
import { appTimeSelector } from 'shared/app/shell';
import { getShortStoreNumber } from 'shared/app/bundles/menu/util/get-filter-query';
import {
  IN_STORE_FEATURE_CODE,
  DRIVE_THRU_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
  CURBSIDE_FEATURE_CODE,
} from 'shared/universal/pickup-feature-codes';

export const preOrderPickupEstimateSelector = (state) =>
  state.ordering?.preOrderEstimate;

const selectedStoreData = (state) => state?.ordering?.selectedStore;

export const orderingSelector = (state) => {
  return state?.ordering;
};

export const selectedStoreSelector = createSelector(
  selectedStoreData,
  (selectedStore) => selectedStore?.location
);

export const selectedStoreMopSvcOnly = createSelector(
  selectedStoreSelector,
  (selectedStore) => !(selectedStore?.store?.acceptsNonSvcMop ?? false)
);

export const userDidClearStoreSelector = createSelector(
  selectedStoreData,
  (selectedStore) => selectedStore?.userDidClear
);

export const needsConfirmationSelector = createSelector(
  selectedStoreData,
  (selectedStore) => selectedStore?.needsConfirmation
);

const storeConfirmationTimestampSelector = createSelector(
  selectedStoreData,
  (selectedStore) => selectedStore?.storeConfirmationTimestamp ?? 0
);

export const storeIsLoadingSelector = createSelector(
  selectedStoreData,
  (selectedStore) => selectedStore?.isLoading
);

export const userConfirmedSelector = createSelector(
  selectedStoreData,
  (selectedStore) => selectedStore?.userConfirmed
);

/* eslint-disable max-params */
export const storeNeedsConfirmationSelector = createSelector(
  needsConfirmationSelector,
  appTimeSelector,
  storeConfirmationTimestampSelector,
  storeConfirmationTimeoutSelector,
  (
    needsConfirmation,
    appTime,
    storeConfirmationTimestamp,
    storeConfirmationTimeout
  ) =>
    needsConfirmation ||
    appTime - storeConfirmationTimeout > storeConfirmationTimestamp
);
/* eslint-enable max-params */

export const promptForDeliveryTypeSelector = createSelector(
  selectedStoreSelector,
  (selectedStore) =>
    Boolean(
      selectedStore?.store?.regulations?.find(
        (regulation) => regulation === 'TAX_FOR_OUT_OF_STORE_CONSUMPTION'
      )
    )
);

export const selectedStoreShortNumberSelector = createSelector(
  selectedStoreSelector,
  (selectedStore) => getShortStoreNumber(selectedStore?.store?.storeNumber)
);

export const currentSessionMostRecentOrderSelector = createSelector(
  orderingSelector,
  (ordering) => {
    return ordering?.order?.previous ?? null;
  }
);

export const previousCartSelector = createSelector(
  orderingSelector,
  (ordering) => Object.values(ordering?.cart?.previous ?? {})
);

export const mostRecentOrderItemsSelector = createSelector(
  previousCartSelector,
  (previousCart) => {
    if (
      previousCart &&
      typeof previousCart === 'object' &&
      Object.keys(previousCart).length
    ) {
      const itemsList = Object.entries(previousCart).map((item) => item[1]);
      return itemsList;
    }
    return null;
  }
);

export const pickupFeatureMap = {
  [IN_STORE_FEATURE_CODE]: {
    name: 'InStore',
  },
  [DRIVE_THRU_FEATURE_CODE]: {
    name: 'DriveThru',
  },
  [OUTDOOR_FEATURE_CODE]: {
    name: 'Outdoor',
    path: '/pickup/outdoor',
  },
  [CURBSIDE_FEATURE_CODE]: {
    name: 'Curbside',
    path: '/pickup/curbside',
  },
};

export const selectedPickupOptionPathSelector = createSelector(
  currentSessionMostRecentOrderSelector,
  (mostRecentOrder) => {
    const previousPickupOption = mostRecentOrder?.pickupOption;
    return pickupFeatureMap[previousPickupOption]?.path ?? null;
  }
);

export const selectedPickupOptionNameSelector = createSelector(
  currentSessionMostRecentOrderSelector,
  (mostRecentOrder) => {
    const previousPickupOption = mostRecentOrder?.pickupOption;
    return pickupFeatureMap[previousPickupOption]?.name;
  }
);

export const rawOrderPickupTimeSelector = (state) =>
  state.ordering?.orderPickupTime;

const getEstimateInMinutes = (estimateInSeconds) => {
  const parsedEstimateInSeconds = parseInt(estimateInSeconds, 10);
  if (Number.isInteger(parsedEstimateInSeconds)) {
    return Math.round(parsedEstimateInSeconds / 60);
  }
  return null;
};

const formatTime = (isoDateTime, locale) => {
  const formatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const dateObject = new Date(isoDateTime);
  const formattedTime = formatter.format(dateObject);
  return formattedTime;
};

export const shouldGetPreOrderPickupEstimateSelector = createSelector(
  preOrderPickupEstimateSelector,
  selectedStoreSelector,
  (preOrderPickupEstimate, selectedStore) =>
    preOrderPickupEstimate?.shortStoreNumber !==
    getShortStoreNumber(selectedStore?.store?.storeNumber)
);

export const preOrderPickupEstimateTimeSelector = createSelector(
  shouldGetPreOrderPickupEstimateSelector,
  preOrderPickupEstimateSelector,
  (shouldGetPreOrderPickupEstimate, preOrderPickupEstimate) => {
    const {
      preOrderEstimate: preOrderEstimateInSeconds,
      preOrderEstimateMin: preOrderEstimateMinInSeconds,
      preOrderEstimateMax: preOrderEstimateMaxInSeconds,
    } = preOrderPickupEstimate ?? {};

    if (!shouldGetPreOrderPickupEstimate && preOrderEstimateInSeconds) {
      const preOrderEstimate = getEstimateInMinutes(preOrderEstimateInSeconds);
      const preOrderEstimateMin = getEstimateInMinutes(
        preOrderEstimateMinInSeconds
      );
      const preOrderEstimateMax = getEstimateInMinutes(
        preOrderEstimateMaxInSeconds
      );
      return {
        preOrderEstimate,
        preOrderEstimateMin,
        preOrderEstimateMax,
        preOrderEstimateInSeconds,
      };
    }
    return null;
  }
);

export const orderPickupTimeSelector = createSelector(
  rawOrderPickupTimeSelector,
  currentLocaleSelector,
  currentSessionMostRecentOrderSelector,
  (rawOrderPickupTime, currentLocale, currentSessionMostRecentOrder) => {
    const {
      orderTime,
      orderId,
      pickupTime: orderPickupTimeIsoFormat,
      waitTimeEstimate: waitTimeEstimateInSeconds,
      waitTimeEstimateMin: orderPickupTimeMinInSeconds,
      waitTimeEstimateMax: orderPickupTimeMaxInSeconds,
      loading: orderPickupTimeLoading,
      pickupOverlaySeen,
      preOrderEstimates,
    } = rawOrderPickupTime ?? {};

    if (orderPickupTimeIsoFormat) {
      const orderPickupTime = formatTime(
        orderPickupTimeIsoFormat,
        currentLocale
      );
      const orderPickupTimeMin = getEstimateInMinutes(
        orderPickupTimeMinInSeconds
      );
      const orderPickupTimeMax = getEstimateInMinutes(
        orderPickupTimeMaxInSeconds
      );

      return {
        pickupOverlaySeen,
        orderPickupTimeLoading,
        orderPickupTime,
        orderPickupTimeMin,
        orderPickupTimeMax,
        orderPickupWaitTimeEstimateInSeconds: waitTimeEstimateInSeconds,
        orderId,
      };
    }

    const {
      preOrderEstimateInSeconds,
      preOrderEstimate: preOrderEstimateInMinutes,
      preOrderEstimateMin: orderPickupTimeMin,
      preOrderEstimateMax: orderPickupTimeMax,
    } = preOrderEstimates ?? {};

    if (orderTime && preOrderEstimateInMinutes) {
      const mostRecentOrderId = currentSessionMostRecentOrder?.pricing?.orderId;

      const orderPickupTime = new Date(
        orderTime + preOrderEstimateInMinutes * 60 * 1000
      ).toLocaleString(currentLocale, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });

      return {
        pickupOverlaySeen,
        orderPickupTimeLoading,
        orderPickupTime,
        orderPickupTimeMin,
        orderPickupTimeMax,
        orderPickupWaitTimeEstimateInSeconds: preOrderEstimateInSeconds,
        orderId: mostRecentOrderId,
        isPreOrderFallback: true,
      };
    }

    return { orderPickupTimeLoading };
  }
);

export const guestEmailMarketingOptInSelector = (state) =>
  state?.ordering?.order?.guestEmailMarketingOptIn === true;

// selected ordering payments (pay & reload and pay)
export const selectedPaymentSelector = (state) =>
  state?.ordering?.selectedPayment;

export const selectedReloadSourceIdSelector = (state) =>
  state?.ordering?.selectedReloadSourceId;

/**
 * Scheduled Ordering Mocked Selectors until we get live data
 */
export const selectedStoreSupportsScheduledOrderingSelector = createSelector(
  selectedStoreSelector,
  (selectedStore) => {
    return selectedStore?.store?.amenities?.some(
      (amenity) => amenity.code === 'SO'
    );
  }
);

export const selectedStoreTimeSlotsSelector = createSelector(
  selectedStoreData,
  (selectedStore) => {
    return selectedStore?.scheduledOrdering?.slots;
  }
);

export const firstAvailableTimeSlotSelector = createSelector(
  selectedStoreTimeSlotsSelector,
  (timeSlots) => {
    return timeSlots?.find((timeSlot) => timeSlot.isAvailable);
  }
);

export const scheduledOrderingMockStateSelector = createSelector(
  selectedStoreData,
  (selectedStore) => {
    return selectedStore?.scheduledOrderingMockState;
  }
);

export const numberOfTimeSlotsAvailable = createSelector(
  selectedStoreTimeSlotsSelector,
  (timeSlots) => {
    let numberAvailable = 0;

    timeSlots?.map((timeSlot) => {
      if (timeSlot.isAvailable) {
        numberAvailable += 1;
      }
    });
    return numberAvailable;
  }
);

/*
  Returns true only if Flag on and Locale Supported
*/
export const scheduledOrderingEnabledSelector = createSelector(
  configSelector,
  (config) => {
    return config?.scheduledOrderingLocaleSupported;
  }
);

export const scheduledTimeSlotLabelSelector = createSelector(
  orderingSelector,
  (orderingData) => orderingData?.order?.scheduledTimeSlot
);

export const scheduledTimeSlotSelector = createSelector(
  scheduledTimeSlotLabelSelector,
  selectedStoreTimeSlotsSelector,
  (timeSlotLabel, timeSlots) => {
    if (!timeSlotLabel || !timeSlots) return null;

    const scheduledTimeSlotFullObject = timeSlots?.find(
      (timeSlot) => timeSlot?.label === timeSlotLabel
    );

    if (scheduledTimeSlotFullObject) {
      return {
        slotId: scheduledTimeSlotFullObject?.id,
        startTime: scheduledTimeSlotFullObject?.startTime,
        endTime: scheduledTimeSlotFullObject?.endTime,
      };
    }
    return null;
  }
);

export const timeSlotsInLoadingStateSelector = createSelector(
  selectedStoreData,
  (selectedStore) => {
    return selectedStore?.scheduledOrderingTimeSlotsLoading;
  }
);
