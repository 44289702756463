import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import PLFrap from '@starbucks-web/pattern-library/lib/components/frap';
import { commonMessages } from 'shared/app/messages/common-words';

const Frap = forwardRef(({ ...otherProps }, ref) => {
  const { formatMessage } = useIntl();
  const loadingAriaLiveAnnouncement = formatMessage(
    commonMessages.loadingAnnouncement
  );

  return (
    <PLFrap
      loadingAriaLiveAnnouncement={loadingAriaLiveAnnouncement}
      {...otherProps}
      ref={ref}
    />
  );
});

export default Frap;
