const config = require('config');
const { get } = require('lodash');
const formatUrl = require('../../universal/format-url');

// radius in km
const EARTH_RADIUS = 6371;
const TILE_SIZE = 256;
const tilePixelCenter = TILE_SIZE / 2;
const pixelsPerLngDegree = TILE_SIZE / 360;
const pixelsPerLngRadian = TILE_SIZE / (2 * Math.PI);

// converts latitudinal/longitudinal degrees to radians
const degreesToRadians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

const radiansToDegrees = function (radians) {
  return radians / (Math.PI / 180);
};

// Returns distance in kilometers (1 meter precision) between two lat/lng positions.
// Uses Law of Cosines: http://gis.stackexchange.com/a/4909
const getDistance = function (posA, posB) {
  const startLat = degreesToRadians(posA.lat || posA.latitude);
  const startLng = degreesToRadians(posA.lng || posA.longitude);
  const endLat = degreesToRadians(posB.lat || posB.latitude);
  const endLng = degreesToRadians(posB.lng || posB.longitude);
  const partialResult =
    Math.sin(startLat) * Math.sin(endLat) +
    Math.cos(startLat) * Math.cos(endLat) * Math.cos(startLng - endLng);
  // Floating point rounding errors can produce values slightly larger than 1; Bound to ≤ 1.
  const distance = Math.acos(Math.min(partialResult, 1)) * EARTH_RADIUS;
  return Number(distance.toFixed(3));
};

// returns a new lat/lng value given a starting lat/lng and a radius in meters
const getRadiusLatLng = function (userGeo, radius) {
  const startLat = degreesToRadians(userGeo.lat);
  const startLng = degreesToRadians(userGeo.lng);
  // angular distance covered on earth's surface - convert Earth radius to meters first
  const dist = parseFloat(radius) / (EARTH_RADIUS * 1000);
  // 0 degrees angle from starting point expressed as radians
  const destAng = degreesToRadians(0);

  let destLat = Math.asin(
    Math.sin(startLat) * Math.cos(dist) +
      Math.cos(startLat) * Math.sin(dist) * Math.cos(destAng)
  );
  destLat = (destLat * 180) / Math.PI;

  let destLng =
    startLng +
    Math.atan2(
      Math.sin(destAng) * Math.sin(dist) * Math.cos(startLat),
      Math.cos(dist) - Math.sin(startLat) * Math.sin(destLat)
    );
  destLng = (destLng * 180) / Math.PI;

  return { lat: destLat, lng: destLng };
};

// returns conversion of a lat/lng object to a google maps Point
// https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
const getPointFromLatLng = function (latLng, opts) {
  opts = opts || { asPlainObject: false };
  const siny = Math.max(Math.sin(degreesToRadians(latLng.lat)), -0.9999);
  const x = TILE_SIZE * (0.5 + latLng.lng / 360);
  const y =
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI));
  if (opts.asPlainObject) {
    return { x, y };
  }
  return new window.google.maps.Point(x, y);
};

// returns conversion of a google maps Point to a lat/lng object
const getLatLngFromPoint = function (point) {
  const lng = (point.x - tilePixelCenter) / pixelsPerLngDegree;
  const latRadians = (point.y - tilePixelCenter) / -pixelsPerLngRadian;
  const lat = radiansToDegrees(
    2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2
  );

  return { lat, lng };
};

// Takes two lat/lng objects and compares them to 6 digits of precision,
// returning true if the values are different
const latLngAreDifferent = function (firstLoc, secondLoc) {
  const firstLat = parseFloat((firstLoc.lat || firstLoc.latitude).toFixed(6));
  const firstLng = parseFloat((firstLoc.lng || firstLoc.longitude).toFixed(6));
  const secondLat = parseFloat(
    (secondLoc.lat || secondLoc.latitude).toFixed(6)
  );
  const secondLng = parseFloat(
    (secondLoc.lng || secondLoc.longitude).toFixed(6)
  );

  if (firstLat !== secondLat || firstLng !== secondLng) {
    return true;
  }

  return false;
};

const getPositionFromMapInstance = function (instance) {
  const zoomLevel = instance?.getZoom();
  const center = instance?.getCenter();
  // Coordinates returned by `getCenter` do not wrap at 180 degrees,
  // so we initialize a new `LatLng` object to wrap them.
  const wrappedCenter = new window.google.maps.LatLng({
    lat: center.lat(),
    lng: center.lng(),
  });

  return {
    lat: wrappedCenter.lat(),
    lng: wrappedCenter.lng(),
    zoomLevel,
  };
};

const getStaticMapUrl = ({ width, height, selectedStore }) => {
  // offset center of map so icon is centered but bottom tip still points to location
  const MAP_LATITUDE_OFFSET = 0.0005;

  const IMAGES_HOST = config.get('universal.staticUrls.images.host');
  const IMAGES_PATH = config.get('universal.staticUrls.images.path');
  const IMAGES_URL = formatUrl(IMAGES_HOST, IMAGES_PATH);
  // use hard-code base url as using the configured value won't work for non-prod environments
  const MARKER_URL = `https://app.starbucks.com${IMAGES_URL}/location-icon.png`;
  const ZOOM = config.get('storeConfirmMapZoom');

  const latitude = get(selectedStore, 'coordinates.latitude');
  const offsetLatitude = latitude + MAP_LATITUDE_OFFSET;
  const longitude = get(selectedStore, 'coordinates.longitude');

  return `/apiproxy/v1/locations/static-map?center=${offsetLatitude},${longitude}&zoom=${ZOOM}&size=${width}x${height}&scale=2&markers=icon:${MARKER_URL}%7C${latitude},${longitude}`;
};

module.exports = {
  getPositionFromMapInstance,
  getDistance,
  degreesToRadians,
  getRadiusLatLng,
  getPointFromLatLng,
  getLatLngFromPoint,
  latLngAreDifferent,
  getStaticMapUrl,
};
