import {
  FETCH_ACCRUAL_EARN_RATES,
  FETCH_ACCRUAL_EARN_RATES_SUCCESS,
  FETCH_ACCRUAL_EARN_RATES_ERROR,
} from './actions';

import { GET_ACCRUAL_EARN_RATES } from 'shared/universal/gql-operation-ids';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

const transformEarnRates = (data) => {
  if (!data) {
    return data;
  }

  return data.reduce((acc, type) => {
    acc[type.paymentType] = {
      standardEarnRate: type.standardEarnRate,
      employeeEarnRate: type.employeeEarnRate,
    };
    return acc;
  }, {});
};

export const fetchAccrualEarnRates =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_ACCRUAL_EARN_RATES });
    return gqlFetch({
      operationId: GET_ACCRUAL_EARN_RATES,
      destinationType: API_PROXY_V1,
      includeRisk: true,
    })
      .then((data) => {
        const payload = transformEarnRates(data?.accrualEarnRates ?? null);
        dispatch({ type: FETCH_ACCRUAL_EARN_RATES_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ACCRUAL_EARN_RATES_ERROR, error });
      });
  };
