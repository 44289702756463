import {
  GET_PRIVACY_PERMISSIONS_STATUS,
  GET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
  GET_PRIVACY_PERMISSIONS_STATUS_ERROR,
  SET_PRIVACY_PERMISSIONS_STATUS,
  SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
  SET_PRIVACY_PERMISSIONS_STATUS_ERROR,
} from './types';
import {
  GET_PRIVACY_PERMISSIONS,
  SET_PRIVACY_PERMISSIONS,
} from 'shared/universal/gql-operation-ids';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

export const getPrivacyPermissions =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: GET_PRIVACY_PERMISSIONS_STATUS });
    const variables = {
      'types': ['PRIVACY_TARGETED_ADS'],
    };

    return gqlFetch({
      variables,
      operationId: GET_PRIVACY_PERMISSIONS,
      destinationType: API_PROXY_V1,
      allowNonFatalErrors: true,
    })
      .then((result) => {
        dispatch({
          type: GET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_PRIVACY_PERMISSIONS_STATUS_ERROR,
          error,
        })
      );
  };

export const setPrivacyPermissions =
  ({ optIn, email = null }) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: SET_PRIVACY_PERMISSIONS_STATUS });
    const variables = {
      'permissionInput': {
        ...(email && { email }),
        'type': 'PRIVACY_TARGETED_ADS',
        optIn,
      },
    };

    return gqlFetch({
      variables,
      operationId: SET_PRIVACY_PERMISSIONS,
      destinationType: API_PROXY_V1,
    })
      .then((result) => {
        const optedIn = result?.setPermission?.optedIn ?? true;
        const payload = {
          permissions: [
            {
              'type': 'PRIVACY_TARGETED_ADS',
              optedIn,
              explicit: true,
            },
          ],
        };
        dispatch({ type: SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: SET_PRIVACY_PERMISSIONS_STATUS_ERROR, error });
        // re-throw to handle in the component
        throw error;
      });
  };
