import {
  FETCH_ONBOARDING_FLAGS,
  FETCH_ONBOARDING_FLAGS_SUCCESS,
  FETCH_ONBOARDING_FLAGS_ERROR,
  UPDATE_ONBOARDING_FLAG,
  UPDATE_ONBOARDING_FLAG_SUCCESS,
  UPDATE_ONBOARDING_FLAG_ERROR,
} from '../../actions';
import {
  GET_ONBOARDING_FLAGS,
  UPDATE_ONBOARDING_FLAG as UPDATE_ONBOARDING_FLAG_ID,
} from 'shared/universal/gql-operation-ids';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

export const fetchOnboardingFlags =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_ONBOARDING_FLAGS });

    return gqlFetch({
      operationId: GET_ONBOARDING_FLAGS,
      destinationType: API_PROXY_V1,
      allowNonFatalErrors: true,
    })
      .then((results) => {
        dispatch({
          type: FETCH_ONBOARDING_FLAGS_SUCCESS,
          payload: results.onboardingFlags,
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_ONBOARDING_FLAGS_ERROR,
          error,
        })
      );
  };

export const updateOnboardingFlag =
  (flagName, flagValue) =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: UPDATE_ONBOARDING_FLAG });
    const variables = { flagName, flagValue };

    return gqlFetch({
      variables,
      operationId: UPDATE_ONBOARDING_FLAG_ID,
      destinationType: API_PROXY_V1,
      allowNonFatalErrors: true,
    })
      .then((payload) => {
        dispatch({ type: UPDATE_ONBOARDING_FLAG_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: UPDATE_ONBOARDING_FLAG_ERROR, error });
      });
  };
