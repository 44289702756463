import {
  REQUEST_LOCATION_BY_NUMBER,
  REQUEST_LOCATION_BY_NUMBER_SUCCESS,
  REQUEST_LOCATION_BY_NUMBER_ERROR,
} from './types';

import { getShortStoreNumber } from 'shared/app/bundles/menu/util/get-filter-query';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

import { GET_STORE_BY_NUMBER } from 'store-locator/universal/gql-operation-ids';

export const fetchLocationByNumber = (number) => {
  return (dispatch, getState, { gqlFetch }) => {
    const storeNumber = getShortStoreNumber(number);
    const query = {
      operationId: GET_STORE_BY_NUMBER,
      destinationType: API_PROXY_V1,
      variables: { storeNumber },
    };

    dispatch({ type: REQUEST_LOCATION_BY_NUMBER });

    return gqlFetch(query)
      .then((data) => {
        dispatch({
          type: REQUEST_LOCATION_BY_NUMBER_SUCCESS,
          payload: data?.storeByNumber,
        });
        return { store: data?.storeByNumber };
      })
      .catch((err) => {
        const error = {
          status: err.status,
          statusText: err.message,
        };
        dispatch({ type: REQUEST_LOCATION_BY_NUMBER_ERROR, error });
      });
  };
};
